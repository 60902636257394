import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import getKey from 'lodash/get';

// import {
//   StarOutlined,
//   StarFilled,
// } from '@ant-design/icons';

import AntdRate from 'antd/lib/rate';
import {
  CheckCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import { Title, Body } from './Text';
import { Space, Row, Column } from './Layout';
import Score, { ScoreTitle } from './Score';
import StarScore from './StarScore';

import Types from '../../common/modules/types';

const Star = styled(AntdRate)`
  color: ${({ theme }) => theme.less.primaryColor};
`;

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;

  border-radius: 10px;
  background: white;
  border: 1px solid #ddd;

  @media (min-width: 1024px) and (hover: hover) {
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000),
      opacity 400ms,
      border 500ms;

    &:hover {
      /* transition-delay: 300ms !important; */
      border: 1px solid ${({ theme }) => theme.less.primary};
      z-index: 999999;

      transform: ${({ $isReport }) => ($isReport ? 'scale(1.35)' : 'scale(1)')};
    };

    *:hover > &:not(:hover) { opacity: 0.5; }

    /* transition-delay: 300ms !important; */
    ${({ $isReport }) => (
      $isReport
      ? css`
          &:hover .grid-item-text-wrapper {
            width: 50%;
            transform: scale(0.8);
          }
          &:hover .grid-item-content {
            transform: translateY(-10%);
          }
        `
      : css`
          &:hover .grid-item-text-wrapper {
            /* transform: scale(0.8); */
          }
        `
    )}
  }
`;

const ImageWrapper = styled.div`
  aspect-ratio: 1;
  margin: 10px;
  width: calc(100% - 20px);
  border-radius: 10px;
  overflow: hidden;
  background: hsla(0, 0%, 0%, 0.03);
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  opacity: 0;
  &[src] {
    opacity: 1;
  }
`;

const Line = styled.div`
  width: calc(100% - 20px);
  margin: auto;
  height: 1px;
  background: #ddd;
`;

const Content = styled.div`
  padding: 20px;

  @media (min-width: 1024px) and (hover: hover) {
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
  }
`;

const TextWrapper = styled(Column)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  * {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: 1024px) and (hover: hover) {
    transform-origin: top left;
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000), width 300ms;
  }
`;

const Scores = styled(Row)`
  @media (min-width: 1024px) and (hover: hover) {
    transform-origin: top right;
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

    ${Wrapper}:hover & {
      /* transition-delay: 300ms !important; */
      justify-content: flex-end;
      transform: translate(0, -140%) scale(0.7);
    }
  }
`;

const ScoresTitle = styled(Row)`
  overflow: hidden;
  text-overflow: hidden;
  @media (min-width: 1024px) and (hover: hover) {
    transform-origin: top left;
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

    ${Wrapper}:hover & {
      /* transition-delay: 300ms !important; */
      /* justify-content: flex-end; */
      /* transform: scale(0.7); */
      /* font-size: 70%; */
    }
  }
`;

const SelectedIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 20px;
  padding: 0px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: hsla(0,0%,0%,0.03);
  /* > ul {
    transform: translateY(-1px);
  } */
  > * {
    pointer-events: none !important;
  }
  > span {
    font-size: 100%;
    color: ${({ theme }) => theme.less.textColor};
    opacity: 0.2;
  }
  &:hover, &[data-selected="true"] {
    background: ${({ theme }) => theme.less.primaryColor};
  }
  &:hover > span, &[data-selected="true"] > span {
    color: ${({ theme }) => theme.less.primaryColor};
    color: white;
    opacity: 1;
  }
`;

function SelectedIconButton({ itemId, selected, onClick }) {
  const ref = useRef();
  // useEffect(
  //   () => {
  //     const callback = (event) => {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       console.log(itemId, selected, onClick);
  //       if (onClick) {
  //         onClick(itemId, selected);
  //       }
  //     };
  //     const { current } = ref;
  //     if (current) {
  //       current.addEventListener('click', callback);
  //     }
  //     return () => {
  //       current.removeEventListener('click', callback);
  //     };
  //   },
  //   [
  //     itemId,
  //     selected,
  //     onClick,
  //   ],
  // );
  return (
    <SelectedIconWrapper
      ref={ref}
      data-selected={!!selected}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick(itemId, selected);
      }}
    >
      <CheckOutlined />
      {/* <Star
        count={1}
        value={selected ? 1 : 0}
      /> */}
    </SelectedIconWrapper>
  );
}

const StyledScore = styled(Score)`
  @media (min-width: 1024px) and (hover: hover) {
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

    ${Wrapper}:hover &:first-child {
      /* transition-delay: 300ms !important; */
      transform: translate(95%, -100%);
    }

    &:first-child { width: 135px; }
    &:last-child { width: 115px; }
  }
`;

const StyledScoreTitle = styled(ScoreTitle)`
  @media (min-width: 1024px) and (hover: hover) {
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

    /* &:first-child { width: 135px; } */
    /* &:last-child { width: 115px; } */
  }
  ${({ $ellipsis }) => $ellipsis && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `};
`;

const Badges = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) and (hover: hover) {
    width: 62px;

    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

    ${Wrapper}:hover & {
      /* transition-delay: 300ms !important; */
      transform: translate(-150%, -12%) scale(0.7);
    }
  }
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  background: ${({ theme }) => theme.less.primaryColor};
  opacity: ${({ $value }) => ($value * 0.2)};
  white-space: nowrap;

  filter: ${({ $isVisible }) => ($isVisible ? 'opacity(1)' : 'opacity(0)')};

  &:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    filter: opacity(0);

    @media (min-width: 1024px) and (hover: hover) {
      transition:
        transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000),
        filter 600ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

      ${Wrapper}:hover & {
        /* transition-delay: 300ms !important; */
        filter: opacity(1);
        transform: translate(30%, calc(100% + 3px));
      }
    }
  }
`;

const Stars = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px 5px;

  position: absolute;
  bottom: 0;
  opacity: 0;

  transform: scale(0.92) translateY(-40%);

  @media (min-width: 1024px) and (hover: hover) {
    transition:
      transform 700ms cubic-bezier(0.075, 0.820, 0.165, 1.000),
      opacity 600ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

    ${Wrapper}:hover & {
      opacity: 1;
      transform: scale(0.92) translateY(-10%);
    }
  }
`;

export function TrackerItem({
  item,
  pageProps,
  selected,
  onSelectedToggle,
  _viewer,
}) {
  const isReport = item.status === 'REPORT';

  const {
    scores,
    metrics,
  } = useMemo(
    () => (
      isReport
      ? (
          Types.REPORT_VALUES_LIST
          // .filter((score) => score.type === 'SCORE')
          .reduce(
            (agr, config) => {
              const isSkipped = (
                config.getIsSkipped(item.marketingType)
                // || config.getIsSkipped(item.marketingKind)
              );
              console.log(config.label, isSkipped);
              if (isSkipped) {
                return agr;
              }
              // const scaledValue = score
              //   .getScaledValue(item[score.key], item.marketingType);
              const scaledValue = item[config.key];
              // const scaleLength = score.getScaleLength(item.marketingType);
              const scaleLength = 5;
              const result = {
                key: config.key,
                title: config.label,
                scale: scaleLength,
                score: scaledValue,
              };
              if (config.type === 'SCORE') {
                result.badge = Types.SCORE_VALUE_DESCRIPTIONS_MAP[scaledValue]
                  ?.badge;
                agr.scores.push(result);
              } else if (config.type === 'METRIC') {
                agr.metrics.push(result);
              }
              return agr;
            },
            {
              scores: [],
              metrics: [],
            },
          )
        )
      : {
          scores: [],
          metrics: [],
        }
    ),
    [item, isReport],
  );

  const textWrapperBodyParts = [
    item.marketingTypeLabel,
    item.brand?.name,
  ].filter(part => part?.length > 0);

  return (
    <Wrapper
      key={item._id}
      className="grid-item"
      to={pageProps.gridItemLink.replace(
        /:([^/]+)/g,
        (all, path) => getKey(item, path)
      )}
      $isReport={isReport}
    >
      <ImageWrapper>
        <Image src={item?.image?.src} />
      </ImageWrapper>
      <Line />
      <Space $height={4} />
      <Content className="grid-item-content">
        <Row $justify="space-between" $gap={4}>
          <TextWrapper className="grid-item-text-wrapper" $isReport={isReport}>
            <Title>{item.name}</Title>
            <Body>
              {
                textWrapperBodyParts.length
                ? textWrapperBodyParts.join(', ')
                : '‎'
              }
            </Body>
          </TextWrapper>
          {
            isReport
            ? (
                <Badges>
                  {/* {!!scores[0].badge && (
                    <Badge $value={scores[0].score}>{scores[0].badge}</Badge>
                  )} */}
                  <Badge
                    $isVisible={!!scores[0].badge}
                    $value={scores[0].score}
                  >
                    {scores[0].badge}
                  </Badge>
                  {!!scores[1].badge && (
                    <Badge
                      $value={scores[1].score}
                    >
                      {scores[1].badge}
                    </Badge>
                  )}
                </Badges>
              )
            : null
          }
        </Row>
        <Space $height={12} />
        {
          isReport
          ? (
              <Scores $gap={12}>
                {scores.map(({ key, title, scale, score }) => (
                  <StyledScore
                    key={key}
                    title={title}
                    scale={scale}
                    score={score}
                  />
                ))}
              </Scores>
            )
          : (
              <ScoresTitle $gap={12}>
                <StyledScoreTitle
                  title="Status:"
                  value={Types.VISUAL_STATUS_LABELS_MAP[item.status]}
                />
              </ScoresTitle>
            )
        }
      </Content>
      {
        isReport
        ? (
            <Stars>
              {metrics.map(({ key, title, scale, score }) => (
                <StarScore
                  key={key}
                  title={title}
                  scale={scale}
                  score={score}
                />
              ))}
            </Stars>
          )
        : null
      }
      {
        pageProps.isTablet
        ? null
        : (
            <SelectedIconButton
              itemId={item._id}
              selected={selected}
              onClick={onSelectedToggle}
            />
          )
      }
      {
        // selected
        // ? (
            // <SelectedIconWrapper>
            //   {
            //     selected
            //     ? <StarFilled onClick={onSelectedToggle} />
            //     : <StarOutlined onClick={onSelectedToggle} />
            //   }
            // </SelectedIconWrapper>
        //   )
        // : null
      }
    </Wrapper>
  );
}

export { default as Grid } from './Grid';
