import TypesHelpers from './types.helpers';

const Types = {};

Object.assign(
  Types,
  TypesHelpers.createValues('VISUAL_MARKETING_TYPE', [{
    id: 'SHELF_READY_PACKAGING',
    label: 'Shelf Ready Packaging',
    annotationLabel: 'Posebno pakovanje za policu',
    annotationDescription: 'Posebno pakovanje za policu.',
    annotationValue: 'shelfReadyPackaging',
    annotationExportSchemaId: '65c5313e10570a392ed2982a',
  }, {
    id: 'DISPLAY',
    label: 'Display',
    description: 'Free standing display unit / a shipper display. Always contains items for sale.', // eslint-disable-line max-len
    annotationLabel: 'Posebna Polica',
    annotationDescription: 'Posebna polica ili poseban stalak za proizvode.',
    annotationValue: 'productDisplay',
    annotationExportSchemaId: '65c52eeb10570a392ed2981f',
  }, {
    id: 'AISLE_FIN',
    label: 'Aisle Fin',
    description: 'Perpendicular to the shelf (at a 90 degree angle), to be seen as shoppers approach from the side.', // eslint-disable-line max-len
    annotationLabel: 'Reklama na kraju police',
    annotationDescription: 'Reklama na kraju police koja se vidi kad se prilazi sa strane.', // eslint-disable-line max-len
    annotationValue: 'aisleFin',
    annotationExportSchemaId: '65c52f6110570a392ed29820',
  }, {
    id: 'HANGER',
    label: 'Hanger',
    description: 'A hanging banner or a sign.',
    annotationLabel: 'Viseća reklama',
    annotationDescription: 'Viseća reklama ili znak.',
    annotationValue: 'hanger',
    annotationExportSchemaId: '65c52f8e10570a392ed29821',
  }, {
    id: 'HEADER',
    label: 'Header',
    description: 'Gondola end or shelf header, usually placed on the top shelf.', // eslint-disable-line max-len
    annotationLabel: 'Reklama na vrhu police',
    annotationDescription: 'Reklama na vrhu police.',
    annotationValue: 'header',
    annotationExportSchemaId: '65c52fc410570a392ed29822',
  }, {
    id: 'STANDEE_GATE_STAND',
    label: 'Standee / Gate Stand',
    description: 'Free standing in-store advertisement. Does NOT hold items for sale.', // eslint-disable-line max-len
    annotationLabel: 'Stalak za reklame',
    annotationDescription: 'Stalak za reklamne materijale u prodavnici.',
    annotationValue: 'standeeGateStand',
    annotationExportSchemaId: '65c52ff910570a392ed29823',
  }, {
    id: 'DECAL_SHELF_LINER',
    label: 'Decal / Shelf Liner',
    description: 'Stickers on shelves or fridge doors.',
    annotationLabel: 'Reklamni materijal na mestu cena.',
    annotationDescription: 'Stikeri na mestu cena u policama ili friziderima.',
    annotationValue: 'decalShelfLiner',
    annotationExportSchemaId: '65c5302e10570a392ed29824',
  }, {
    id: 'POSTER_DIGITAL_POSTER',
    label: 'Poster / Digital Poster',
    description: 'A wall poster / digital poster.',
    annotationLabel: 'Zidna reklama preko cele police.',
    annotationDescription: 'Zidna reklama preko cele police ili stakla fizidera.', // eslint-disable-line max-len
    annotationValue: 'posterDigitalPoster',
    annotationExportSchemaId: '65c5305e10570a392ed29825',
  }, {
    id: 'FLOOR_MEDIA',
    label: 'Floor Media',
    description: 'Floor stickers.',
    annotationLabel: 'Reklama na podu',
    annotationDescription: 'Reklama na podu ispred odredjene police.',
    annotationValue: 'floorMedia',
    annotationExportSchemaId: '65c5308e10570a392ed29826',
  }, {
    id: 'END_CAP_SIDES',
    label: 'End Cap Sides',
    annotationLabel: 'Reklama na kraju police',
    annotationDescription: 'Reklama na kraju police.',
    annotationValue: 'endCapSides',
    annotationExportSchemaId: '65c530b810570a392ed29827',
  }, {
    id: 'WOBBLER',
    label: 'Wobbler',
    annotationLabel: 'Viseća reklama na mestu cena',
    annotationDescription: 'Viseća reklama na mestu cena.',
    annotationValue: 'wobbler',
    annotationExportSchemaId: '65c530e410570a392ed29828',
  }, {
    id: 'OTHER',
    label: 'Other / Neutral (Please Specify)',
    annotationLabel: 'Ništa od navedenog',
    annotationDescription: 'Ništa od navedenog.',
    annotationValue: 'other',
    annotationExportSchemaId: '65c5310e10570a392ed29829',
  }]),
  TypesHelpers.createValues('VISUAL_PROMOTION_TYPE', [{
    id: 'DISABLED',
    label: 'No discount',
  }, {
    id: 'DISCOUNT',
    label: 'Discount (-20%, etc.)',
  }, {
    id: 'OTHER',
    label: 'Other Promotion (2 + 1, Free, etc.)',
  }]),
  TypesHelpers.createValues('VISUAL_GOAL', [{
    id: 'PROMOTION',
    label: 'Price Promotion / Discount',
    annotationLabel: 'Popust ili akcija',
    annotationValue: 'pricePromotionDiscount',
    annotationValueOriginal: 'popust_ili_akcija',
    annotationExportSchemaId: 'a6a62e2d65e5beda326a57960',
  }, {
    id: 'NEW_DESIGN',
    label: 'New Design',
    annotationLabel: 'Novi izgled pakovanja',
    annotationValue: 'newDesign',
    annotationValueOriginal: 'novi_izgled_pakovanja',
    annotationExportSchemaId: '80e9fd8ae5a7a193d5f9525d4',
  }, {
    id: 'NEW_PRODUCT',
    label: 'New Product / Flavor',
    annotationLabel: 'Novi proizvod ili ukus',
    annotationValue: 'newProductOrFlavor',
    annotationValueOriginal: 'novi_proizvod_ili_ukus',
    annotationExportSchemaId: 'f4ea98e3d8ca7e219f4b32731',
  }, {
    id: 'LOYALTY_POINTS',
    label: 'Loyalty / Bonus Points',
    annotationLabel: 'Loyalty / Bonus Points',
    annotationValue: 'loyaltyBonusPoints',
    annotationValueOriginal: 'bonus_poeni_kredit_kod_trgovca',
    annotationExportSchemaId: '495e92f100f8b0350c28be44d',
  }, {
    id: 'PRIZE_GAME',
    label: 'Prize Game',
    annotationLabel: 'Nagradna igra',
    annotationValue: 'prizeGame',
    annotationValueOriginal: 'nagradna_igra',
    annotationExportSchemaId: 'd98992a8b3010c7e1286ed3b1',
  }, {
    id: 'BRAND_CAMPAIGN',
    label: 'Branded Campaign',
    annotationLabel: 'Generalna reklama za brend',
    annotationValue: 'brandedCampaign',
    annotationValueOriginal: 'generalna_reklama_za_brend',
    annotationExportSchemaId: '57d28755294319689e25d32ce',
  }, {
    id: 'CHARITY',
    label: 'Charity',
    annotationLabel: 'Charity',
    annotationValue: 'charity',
    annotationValueOriginal: 'dobrotvorna_svrha',
    annotationExportSchemaId: 'b725ebcc6447a83cdcb165dda',
  }, {
    id: 'SHOPPER_NAVIGATION',
    label: 'Shopper Navigation / Retailer Signage',
    annotationLabel: 'Trgovac reklamira sebe ili neka druga oznaka trgovca',
    annotationValue: 'shopperNavigationRetailerSignage',
    annotationValueOriginal: 'trgovac_reklamira_sebe_ili_neka_druga_oznaka_trgovca', // eslint-disable-line max-len
    annotationExportSchemaId: '330f2ef92ab6b4feca00d19b3',
  }, {
    id: 'ECOLOGY',
    label: 'Ecology Or Sustainability Related',
    annotationLabel: 'Nesto vezano za ekologiju (reciklirano, dobro za okolinu, zero waste itd).', // eslint-disable-line max-len
    annotationValue: 'ecologySustainabilityRelated',
    annotationValueOriginal: 'nesto_vezano_za_ekologiju_reciklirano_dobro_za_okolinu_zero_waste_itd', // eslint-disable-line max-len
    annotationExportSchemaId: '5f31ecb0af06e9d69b0f0b1b8',
  }, {
    id: 'SEASONAL',
    label: 'Seasonal Campaign',
    annotationLabel: 'Sezonska reklama/veliki dogadjaj',
    annotationValue: 'seasonalCampaign',
    annotationValueOriginal: 'sezonska_reklama_veliki_dogadjaj',
    annotationExportSchemaId: '75edddb2c555503a1f4dd0b5d',
  }, {
    id: 'OTHER',
    label: 'Other (Please Specify)',
    annotationLabel: 'Ništa od navedenog',
    annotationValue: 'noneOfTheAbove',
    annotationValueOriginal: 'nista_od_navedenog',
    annotationExportSchemaId: '716a1556668f54d240941b4aa',
  }]),
  TypesHelpers.createValues('VISUAL_SEASON', [{
    id: 'NO_SEASON',
    label: 'No Season',
    annotationLabel: 'Bez Sezone',
    annotationValue: 'noSeason',
    annotationExportSchemaId: '65c26d8491c43f66d8f45367',
  }, {
    id: 'CHRISTMAS',
    label: 'Christmas',
    annotationLabel: 'Bozic',
    annotationValue: 'christmas',
    annotationValueOriginal: 'bozic',
    annotationExportSchemaId: '4740279e155f50496f3159b6a',
  }, {
    id: 'EASTER',
    label: 'Easter',
    annotationLabel: 'Uskrs',
    annotationValue: 'easter',
    annotationValueOriginal: 'uskrs',
    annotationExportSchemaId: '1315fee95c2eab8c98c98de7e',
  }, {
    id: 'NEW_YEAR',
    label: 'New Year',
    annotationLabel: 'Nova Godina',
    annotationValue: 'newYear',
    annotationValueOriginal: 'nova_godina',
    annotationExportSchemaId: 'e948e9111d0e6610d98ada250',
  }, {
    id: 'SPORTS_CHAMPIONSHIP',
    label: 'Sports Championship',
    annotationLabel: 'Sportsko prvenstvo',
    annotationValue: 'sportsChampionship',
    annotationValueOriginal: 'sportsko_prvenstvo',
    annotationExportSchemaId: '76e6cb54616792e4fcdf43ee9',
  }, {
    id: 'BACK_TO_SCHOOL',
    label: 'Back To School',
    annotationLabel: 'Povratak u školu',
    annotationValue: 'backToSchool',
    annotationExportSchemaId: '65c26e3191c43f66d8f45368',
  }, {
    id: 'SPONSORSHIP',
    label: 'Sponsorship / Sports Event',
    annotationLabel: 'Sponzorstvo',
    annotationValue: 'sponsorship',
    annotationExportSchemaId: '65c26ec991c43f66d8f45369',
  }, {
    id: 'HALLOWEEN',
    label: 'Halloween',
    annotationLabel: 'Noć Veštica',
    annotationValue: 'halloween',
    annotationExportSchemaId: '65c26f3191c43f66d8f4536a',
  }, {
    id: 'VALENTINES_DAY',
    label: 'Valentines Day',
    annotationLabel: 'Dan zaljubljenih',
    annotationValue: 'valentinesDay',
    annotationExportSchemaId: '65c26f5091c43f66d8f4536b',
  }, {
    id: 'OTHER',
    label: 'Other (Please Specify)',
    annotationLabel: 'Nešto drugo sezonski',
    annotationValue: 'otherSeasonal',
    annotationExportSchemaId: '65c26f9091c43f66d8f4536c',
  }]),
  TypesHelpers.createValues('VISUAL_SIZE', [{
    id: 'SMALL',
    label: 'Small',
  }, {
    id: 'MEDIUM',
    label: 'Medium',
  }, {
    id: 'LARGE',
    label: 'Large',
  }]),
);

export default Types;
