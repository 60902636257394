import getKey from 'lodash/get';

import React from 'react';
import styled, { css } from 'styled-components';

import AntdModal from 'antd/lib/modal';
import AntdTable from 'antd/lib/table';
import AntdButton from 'antd/lib/button';

import Query from 'hive-admin/src/components/Query';

import Color from 'color';

import useQuery from '../modules/useQuery';

import { StarsCompare as Stars } from './Report/StarScore';
import { Bars } from './Report/BarScore';

import { DownloadReport as DownloadCompareReport } from './ComparePDF';

import { Tooltip } from './Popover';

import Types from '../common/modules/types';

const ModalHTML = ({ className, isTablet, ...rest }) => (
  <AntdModal
    wrapClassName={className}
    {...rest}
  />
);

const Modal = styled(ModalHTML)`
  .ant-modal {
    position: fixed;
    ${({ isTablet }) => (
      isTablet
      ? css`
        width: calc(100vw - 20px) !important;
        height: calc(100vh - 20px) !important;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
      `
      : css`
        width: calc(100vw - 80px) !important;
        height: calc(100vh - 80px) !important;
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
      `
    )}
  }
  .ant-modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-body {
    overflow-y: scroll;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  > span {
    width: 100%;
    display: block;
  }
`;

/* eslint-disable max-len */
const Table = styled(AntdTable)`
  .ant-table-scroll {
    position: relative;

    overflow-x: initial;
    overflow-y: initial;

    /* &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0; bottom: 0;
      width: 40px;
      pointer-events: none;
    }

    &:before {
      left: -24px;
      background: linear-gradient(to right, white, transparent);
    }

    &:after {
      right: -24px;
      background: linear-gradient(to left, white, transparent);
    } */
  }

  .ant-table-body {
    margin: 0 -24px 5px;
    padding: 0 24px 15px;
  }

  table {
    border: 1px solid #d9d9d9 !important;
    border-radius: 10px;
    overflow: hidden;

    th:last-of-type,
    td:last-of-type {
      border-right: none;
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: transparent;
  }
  td:has(.compare-cell-winner-value) {
    background: ${({ theme }) => Color(
      theme.less.primaryColor
    ).alpha(0.05).string()} !important;
  }
`;
/* eslint-enable max-len */

const ItemTitle = styled.div`
  font-size: 140%;
  font-weight: bold;
`;

const ItemSubtitle = styled.div`
  font-size: 80%;
  white-space: nowrap;
`;

const ItemImageHTML = ({ src, ...rest }) => (
  <div
    {...rest}
    style={{
      backgroundImage: `url(${src})`,
    }}
  />
);

const ItemImage = styled(ItemImageHTML)`
  display: inline-block;
  width: 200px;
  height: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const ItemName = styled.span`
  font-weight: bold;
`;

function Loader({ ids, client, children }) {
  if (ids.length) {
    return (
      <Query
        client={client}
        url={`/visuals?query=${JSON.stringify({
          where: {
            _id: { IN: ids },
          },
          populate: {
            brand: true,
            category: true,
            department: true,
            organization: true,
          },
        })}`}
      >
        {children}
      </Query>
    )
  }
  return null;
}

function CompareContent({ items: itemsRaw = [], isTablet }) {
  const columns = [
    {
      key: 'label',
      // eslint-disable-next-line arrow-body-style
      render: (text, record, _rowIndex) => {
        return (
          record.title
          ? (
              <>
                <ItemTitle>{record.title}</ItemTitle>
                {
                  record.description && !isTablet
                  ? <ItemSubtitle>{record.description}</ItemSubtitle>
                  : null
                }
              </>
            )
          : null
        );
      },
    },
    ...(
      itemsRaw.map((item, index) => ({
        key: `item${index}`,
        align: 'center',
        // eslint-disable-next-line arrow-body-style
        render: (text, record, _rowIndex) => {
          const value = record[`item${index}`] || 0;
          if (record.key === 'name') {
            return (
              <ItemName>{value}</ItemName>
            );
          }
          if (record.key === 'image') {
            return (
              <ItemImage src={value} />
            );
          }
          if (record.isValue) {
            const className = (
              record.winnerIndex > -1 && record.winnerIndex === index
              ? 'compare-cell-winner-value'
              : undefined
            );
            if (record.type === 'SCORE') {
              return (
                <span>
                  <Bars
                    className={className}
                    score={value}
                    scale={5}
                    // style={{ display: 'inline-block' }}
                    // barStyle={{ width: '16px' }}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      marginBottom: '0px',
                    }}
                    barStyle={{
                      borderRadius: '3px',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </span>
              );
            }
            return (
              <Stars
                className={className}
                score={value}
                stars={5}
                // style={{ display: 'inline-block' }}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }}
                starStyle={{
                  width: '20px',
                  height: '20px',
                  fontSize: '20px',
                }}
              />
            );
          }
          return null;
        },
      }))
    ),
  ];
  const dataSource = [
    {
      key: 'image',
      pullFrom: 'image.src',
      title: null,
    },
    {
      key: 'name',
      pullFrom: 'name',
      title: null,
    },
    ...(
      Types.REPORT_VALUES_LIST
      .filter((item) => ['SCORE', 'METRIC'].includes(item.type))
      // eslint-disable-next-line max-len
      .filter((item) => !item.getIsHidden || !item.getIsHidden(itemsRaw))
      .map(metric => ({
        key: metric.key,
        title: metric.label,
        type: metric.type,
        description: metric.description,
        pullFrom: metric.key,
        isValue: true,
      }))
    ),
  ].map((column) => itemsRaw.reduce(
    (agr, item, index) => {
      agr[`item${index}`] = getKey(item, column.pullFrom || column.key);
      return agr;
    },
    column,
  ));

  dataSource.forEach((row) => {
    if (row.isValue && itemsRaw.length > 1) {
      const array = [...itemsRaw];
      let largestValue = row.item0;
      let largestValueIndex = 0;
      let anyValueDiffers = false;
      for (let i = 1; i < array.length; i++) {
        const value = row[`item${i}`];
        if (value !== largestValue) {
          anyValueDiffers = true;
          if (value > largestValue) {
            largestValue = value;
            largestValueIndex = i;
          }
        }
      }
      // array.sort((a, b) => {
      //   const aValue = row[`item${array.indexOf(a)}`];
      //   const bValue = row[`item${array.indexOf(b)}`];
      //   return aValue > bValue
      //   ? 1
      //   : aValue < bValue
      //   ? -1
      //   : 0;
      // });
      // console.log('winnerIndex', anyValueDiffers, largestValueIndex);
      row.winnerIndex = anyValueDiffers ? largestValueIndex : -1;
    }
  });

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      showHeader={false}
      scroll={{
        x: true,
        y: true,
      }}
      pagination={false}
      bordered
      footer={null}
    />
  );
}

export default function Compare({ ids, client, isTablet }) {
  return (
    <Loader
      ids={ids}
      client={client}
    >
      {({ data }) => {
        const { data: { data: items } = {} } = data || {};
        return (
          <CompareContent
            items={items}
            isTablet={isTablet}
          />
        )
      }}
    </Loader>
  )
}

function Component({ ids, client, isTablet }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [{
    _loading,
    data,
  }] = useQuery(client, {
    url: `/visuals?query=${JSON.stringify({
      where: {
        _id: { IN: ids },
      },
      populate: {
        brand: true,
        category: true,
        department: true,
        organization: true,
      },
    })}`,
    skip: !isOpen || !ids.length,
  });
  const { data: { data: items = [] } = {} } = data || {};
  const button = (
    <AntdButton
      key="compare-action"
      style={{ width: '100%' }}
      disabled={ids?.length < 2}
      onClick={() => setIsOpen(true)}
    >
      Compare
    </AntdButton>
  );
  return (
    <>
      <ButtonWrapper>
        {
          ids?.length < 2
          ? (
              <Tooltip
                // title="Select at least two items to compare"
                content="Select 2-4 items to compare their metrics"
                style={{ width: '100%' }}
              >
                {button}
              </Tooltip>
            )
          : button
        }
        <Modal
          title="Compare"
          visible={isOpen}
          isTablet={isTablet}
          onCancel={() => setIsOpen(false)}
          footer={(
            items?.length < 2
            ? null
            : (
                <DownloadCompareReport
                  data={items}
                >
                  Export PDF Report
                </DownloadCompareReport>
              )
          )}
        >
          <CompareContent
            items={items}
            isTablet={isTablet}
          />
        </Modal>
      </ButtonWrapper>
    </>
  )
}

export const action = ['FilterField', {
  name: 'compare-action',
  section: 'top',
  // eslint-disable-next-line max-len
  propsFromPage: ({
    client,
    queryBuilder,
    restBaseServer,
    searchParams,
    path,
    viewer,
    isTablet,
  }) => ({
    client,
    viewer,
    queryBuilder,
    restBaseServer,
    searchParams,
    pagePath: path,
    isTablet,
  }),
  field: ['FieldReact', {
    key: 'compare-action-button',
    name: 'compare-action-button',
    renderContent: (props) => {
      const ids = (props.searchParams.compare || '').split(',').filter(
        id => id?.length
      );
      return (
        <Component
          {...props}
          ids={ids}
        />
      );
    },
  }],
  skip: [
    props => props.isTablet,
  ],
  buildQuery: () => {},
}];
